<template>
  <form class="form" @submit.prevent="submitForm" :style="formStyle">
    <div class="form-control">
      <label for="tool-name">Tool Name (optional)</label>
      <input id="tool-name" name="tool-name" type="text" v-model.trim="preferredToolName" :style="formElementStyle" />
    </div>
    <div class="form-control">
      <label for="tool-uuid">Tool ID (optional)</label>
      <input id="tool-uuid" name="tool-uuid" type="text" v-model.trim="preferredToolId" :style="formElementStyle" />
    </div>
    <div class="form-control">
      <label for="tool-width">Tool Width ({{ minToolColumns }}-{{ numColumns }}, optional)</label>
      <input id="tool-width" name="tool-width" type="text" v-model.trim="preferredToolWidth" :style="formElementStyle" />
    </div>
    <div class="form-control">
      <label for="tool-height">Tool Height ({{ minToolRows }}-{{ maxToolRows }}, optional)</label>
      <input id="tool-height" name="tool-height" type="text" v-model.trim="preferredToolHeight"
        :style="formElementStyle" />
    </div>
    <div class="form-control">
      <label for="tool-row">Tool Row (0-, optional)</label>
      <input id="tool-row" name="tool-row" type="text" v-model.trim="preferredToolRow" :style="formElementStyle" />
    </div>
    <div class="form-control">
      <label for="tool-col">Tool Column (0-{{ numColumns - minToolColumns }}, optional)</label>
      <input id="tool-col" name="tool-col" type="text" v-model.trim="preferredToolCol" :style="formElementStyle" />
    </div>
    <div class="form-control">
      <button class="form-button" :style="formButtonStyle" @click.prevent="onViewTableFromCSV">View Table (from CSV
        data)</button>
      <button class="form-button" :style="formButtonStyle" @click.prevent="onViewTableFromTSV">View Table (from TSV
        data)</button>
      <button class="form-button" :style="formButtonStyle" @click.prevent="onViewMarkdown">View Markdown</button>
      <button class="form-button" :style="formButtonStyle" @click.prevent="onViewImage">View Image</button>
      <button class="form-button" :style="formButtonStyle" @click.prevent="onViewPdf">View PDF</button>
      <button class="form-button" :style="formButtonStyle" @click.prevent="onViewSunburst(1)">View Sunburst 1</button>
      <button class="form-button" :style="formButtonStyle" @click.prevent="onViewSunburst(2)">View Sunburst 2</button>
      <button class="form-button" :style="formButtonStyle" @click.prevent="onViewTxt">View Plain Text</button>
    </div>
  </form>
</template>

<script>
// Copyright (C) Omics Data Automation, Inc. - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { computed } from 'vue'
import { useStore } from 'vuex'

import { maxToolRows, minToolColumns, minToolRows, numColumns, themeColors, useDebouncedRef } from '@/common/shared.js'

const sunburst1 = {
  'baseTopic': 'Patients',
  'topics': {
    'Gender': ['Female', 'Male'],
    'Age Range': ['<20', '20-35', '35-55', '55-75', '>75'],
    'Cardiac Arrest': ['No Cardiac Arrest', 'Cardiac Arrest'],
    'Simvastatin': ['No Simvastatin', 'Simvastatin'],
  },
  'values': {
    'Male,>75,Cardiac Arrest,Simvastatin': 201,
    'Male,35-55,Cardiac Arrest,Simvastatin': 94,
    'Female,>75,Cardiac Arrest,Simvastatin': 49,
    'Male,55-75,Cardiac Arrest,Simvastatin': 192,
    'Male,<20,Cardiac Arrest,Simvastatin': 5,
    'Male,20-35,Cardiac Arrest,Simvastatin': 30,
    'Female,35-55,Cardiac Arrest,Simvastatin': 33,
    'Female,55-75,Cardiac Arrest,Simvastatin': 35,
    'Female,20-35,Cardiac Arrest,Simvastatin': 8,
    'Female,>75,Cardiac Arrest,No Simvastatin': 5030,
    'Male,35-55,Cardiac Arrest,No Simvastatin': 8969,
    'Female,55-75,Cardiac Arrest,No Simvastatin': 10392,
    'Male,20-35,Cardiac Arrest,No Simvastatin': 3931,
    'Male,>75,Cardiac Arrest,No Simvastatin': 8814,
    'Female,35-55,Cardiac Arrest,No Simvastatin': 6785,
    'Male,55-75,Cardiac Arrest,No Simvastatin': 13401,
    'Female,20-35,Cardiac Arrest,No Simvastatin': 3764,
    'Male,<20,Cardiac Arrest,No Simvastatin': 2797,
    'Female,<20,Cardiac Arrest,No Simvastatin': 3029,
    'Male,35-55,No Cardiac Arrest,Simvastatin': 13697,
    'Male,55-75,No Cardiac Arrest,Simvastatin': 37288,
    'Female,55-75,No Cardiac Arrest,Simvastatin': 9956,
    'Female,>75,No Cardiac Arrest,Simvastatin': 11118,
    'Male,<20,No Cardiac Arrest,Simvastatin': 3374,
    'Male,>75,No Cardiac Arrest,Simvastatin': 33620,
    'Female,20-35,No Cardiac Arrest,Simvastatin': 3531,
    'Female,35-55,No Cardiac Arrest,Simvastatin': 6704,
    'Male,20-35,No Cardiac Arrest,Simvastatin': 6615,
    'Female,<20,No Cardiac Arrest,Simvastatin': 1447,
  },
}

const sunburst2 = {
  baseTopic: 'Patients',
  topics: {
    Gender: ['Female', 'Male'],
    'Age Range': ['<20', '20-35', '35-55', '55-75', '>75'],
    Genomics: ['No Genomics', 'Genomics'],
    Chemo: ['No Chemo', 'Chemo'],
    Diagnosis: ['Breast', 'Colorectal', 'Lung', 'Other'],
  },
  values: {
    'Female,35-55,Genomics,No Chemo,Breast': 1,
    'Female,35-55,Genomics,No Chemo,Colorectal': 3,
    'Female,55-75,Genomics,No Chemo,Lung': 159,
    'Female,35-55,Genomics,No Chemo,Other': 61,
    'Female,<20,Genomics,Chemo,Breast': 27,
    'Female,35-55,Genomics,Chemo,Colorectal': 16,
    'Female,55-75,Genomics,Chemo,Lung': 168,
    'Female,>75,Genomics,Chemo,Other': 105,
    'Male,35-55,Genomics,No Chemo,Colorectal': 11,
    'Male,<20,Genomics,No Chemo,Lung': 101,
    'Male,20-35,Genomics,No Chemo,Other': 58,
    'Male,35-55,Genomics,Chemo,Colorectal': 19,
    'Male,55-75,Genomics,Chemo,Lung': 118,
    'Male,35-55,Genomics,Chemo,Other': 131,
  },
}

export default {
  props: {
    payload: {
      type: Object,
      required: true,
    },
    sessionId: {
      type: String,
      required: true,
    },
    sessionOwnerId: {
      type: String,
      required: true,
    },
    toolWidthInColumns: {
      type: Number,
      required: true,
    },
    toolHeightInRows: {
      type: Number,
      required: true,
    },
    toolId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const store = useStore()

    const formStyle = computed(() => {
      return {
        backgroundColor: themeColors[store.getters.currentThemeName].modalBackgroundColor,
        color: themeColors[store.getters.currentThemeName].modalTextColor,
      }
    })

    const formButtonStyle = computed(() => {
      return {
        backgroundColor: themeColors[store.getters.currentThemeName].secondaryButtonBackgroundColor,
        color: themeColors[store.getters.currentThemeName].secondaryButtonTextColor,
      }
    })

    const formElementStyle = computed(() => {
      return {
        backgroundColor: themeColors[store.getters.currentThemeName].inputElementBorderColor,
        color: themeColors[store.getters.currentThemeName].modalTextColor,
        border: `1px solid ${themeColors[store.getters.currentThemeName].inputElementBorderColor}`,
      }
    })

    const preferredToolId = useDebouncedRef('', 200)
    const preferredToolName = useDebouncedRef('My Cool Federated Analysis Results', 200)
    const preferredToolWidth = useDebouncedRef('', 200)
    const preferredToolHeight = useDebouncedRef('', 200)
    const preferredToolRow = useDebouncedRef('', 200)
    const preferredToolCol = useDebouncedRef('', 200)

    const onAddOrUpdate = async (payload, optToolName) => {
      const toolName = optToolName || 'ViewerTool'
      const toolTitle = preferredToolName.value
      const toolId = preferredToolId.value
      const width = parseInt(preferredToolWidth.value, 10)
      const height = parseInt(preferredToolHeight.value, 10)
      const row = parseInt(preferredToolRow.value, 10)
      const col = parseInt(preferredToolCol.value, 10)
      const id = await window.ux2.addOrUpdateTool(toolName, toolTitle, payload, toolId, width, height, row, col)
      preferredToolId.value = id
    }

    const onViewMarkdown = () => {
      const payload = props.payload.data.viewMarkdown || {}
      onAddOrUpdate(payload)
    }

    const onViewImage = () => {
      const payload = props.payload.data.viewImage || {}
      onAddOrUpdate(payload)
    }

    const onViewPdf = () => {
      const payload = props.payload.data.viewPDF || {}
      onAddOrUpdate(payload)
    }

    const onViewSunburst = (num) => {
      const payload = {
        'data': {
          'body': JSON.stringify((num === 1) ? sunburst1 : sunburst2),
        },
        'url': {
          'baseUrl': 'https://oda-aks-dev-ux2.westus2.cloudapp.azure.com/data-summary-bbtool',
          'method': 'POST',
          'queryParams': {},
        },
      }
      onAddOrUpdate(payload, 'BlackBoxSSRTool')
    }

    const onViewTableFromCSV = () => {
      const payload = props.payload.data.viewTableFromCSV || {}
      onAddOrUpdate(payload)
    }

    const onViewTableFromTSV = () => {
      const payload = props.payload.data.viewTableFromTSV || {}
      onAddOrUpdate(payload)
    }

    const onViewTxt = () => {
      const payload = props.payload.data.viewTxt || {}
      onAddOrUpdate(payload)
    }

    return {
      formButtonStyle,
      formElementStyle,
      formStyle,
      maxToolRows,
      minToolColumns,
      minToolRows,
      numColumns,
      onViewMarkdown,
      onViewImage,
      onViewPdf,
      onViewSunburst,
      onViewTableFromCSV,
      onViewTableFromTSV,
      onViewTxt,
      preferredToolCol,
      preferredToolHeight,
      preferredToolId,
      preferredToolName,
      preferredToolRow,
      preferredToolWidth,
    }
  },
}
</script>

<style lang="scss" scoped>
.form {
  height: 100%;
  max-width: 100%;
  overflow: auto;
  margin: 0;
  padding: 0;
  padding-top: 1rem;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}

// See https://www.quackit.com/html/html_editors/scratchpad/?example=/css/flexbox/tutorial/align_form_elements_with_flexbox_example_2
.form-control {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding: 0.5rem;
}

.form-control>label {
  text-align: left;
  padding: 0.5rem 1rem 0.5rem 0;
  flex: 1;
}

.form-control>input {
  font-size: 1rem;
  flex: 2;
}

.form-control>input,
.form-control>.form-button {
  padding: 0.5rem;
}

.form-control:last-of-type {
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.form-button {
  font: inherit;
  font-size: 1rem;
  margin: 1rem 0;
  min-width: 100px;
  height: 30px;
  line-height: 1.5;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
  border: none;
  cursor: pointer;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.form-button:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(38 143 255 / 50%);
}
</style>